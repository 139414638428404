import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { size } from 'lodash';
import { withTheme } from 'styled-components';
import loadable from '@loadable/component';
import _ from 'lodash';

import { setToObject } from 'common/utils/index';

import { getProductIdsToCompareSelector } from 'common/selectors/compare';
import { fetchProductIdsToCompareFromCookie } from 'common/actions/CompareActions';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

const CompareButtonVariant = loadable(props =>
    import(`./${props.blockVariant}`)
  );

class CompareButton extends PureComponent {
  componentDidMount() {
    const { dispatchFetchProductIds } = this.props;
    dispatchFetchProductIds();
  }
  render() {
    const { itemsCount = 0, theme, ...rest } = this.props;
    return (
        <CompareButtonVariant
          itemsCount={_.toNumber(itemsCount)}
          blockVariant={_.get(blockSettings, 'blockVariant')}
          theme={theme}
          customStyle={_.get(theme, 'compareButton.customStyle', '')}
          {...rest}
        />
    );
  }
}

const blockSettings = {};
const makeMapStateToProps = () => {
  const getProductIdsToCompare = getProductIdsToCompareSelector();
  return (state, props) => {
    const reactiveTemplateConfig = reactiveTemplateConfigSelector(
      'CompareButtonVariant'
    );
    return {
      itemsCount: size(getProductIdsToCompare(state, props)),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchFetchProductIds: () => {
      dispatch(fetchProductIdsToCompareFromCookie());
    }
  };
};

const CompareButtonConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CompareButton);

export default withTheme(CompareButtonConnected);
