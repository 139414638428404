import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_WISHLIST } from 'common/constants/IconConstants';
import { fetchProductIdsToCompareFromCookie } from 'common/actions/CompareActions';
import { capfl } from 'common/utils';

import {
  getWishlistItemsCountSelector,
  isUserLoggedSelector
} from 'common/selectors/user';

import SS from 'common/components/_partials/navigation/_default/_styles';

const WishlistButton = ({ t, wishlistItemsCount, isLogged }) => {
  return (
    <SS.UserActionsLink route="wishlist">
      <SS.UserActionsLinkIcon iconClassName={ICON_WISHLIST}>
        <SS.UserActionsLinkIconText>
          {capfl(t('wishlist'))}
        </SS.UserActionsLinkIconText>
      </SS.UserActionsLinkIcon>
      {wishlistItemsCount && isLogged ? (
        <SS.UserActionsLinkCount>{wishlistItemsCount}</SS.UserActionsLinkCount>
      ) : (
        ''
      )}
    </SS.UserActionsLink>
  );
};

const makeMapStateToProps = () => {
  const getWishlistItemsCount = getWishlistItemsCountSelector();
  const isUserLogged = isUserLoggedSelector();
  return (state, props) => {
    return {
      wishlistItemsCount: getWishlistItemsCount(state, props),
      isLogged: isUserLogged(state, props)
    };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchFetchProductIds: () => {
      dispatch(fetchProductIdsToCompareFromCookie());
    }
  };
};

const WishlistButtonConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WishlistButton);

export default withTranslation()(WishlistButtonConnected);
