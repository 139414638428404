import React, { Component, Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import { withSizes } from 'react-sizes';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';

import { capfl, getMapSizesToPropsCustom } from 'common/utils';
import { withCssUnit } from 'common/components/styleguide/utils';
import Image from 'common/components/_base/Image';
import Link from 'common/components/styleguide/Link';
import List from 'common/components/styleguide/List';
import RouteLink from 'common/components/_base/RouteLink';

import SS from 'common/blocks/navigation/menu/NavigationMenuVariant1/_styles';

const S = {};

S.Wrapper = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    background: #fff;
    display: flex;
    padding: ${({ theme }) => withCssUnit(theme.gutterWidth)};
    flex: 1 0 0;
    flex-wrap: wrap;
  }
`;

S.Banners = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

S.Banner = styled.div`
  width: ${({ bannerSize }) => (100 / bannerSize).toFixed(2) + '%'};
`;

S.Chunk = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
  }
`;

class DropdownContent extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { chunks, chunksCount, banners, parent, t } = this.props;
    return (
      <div>
        <S.Wrapper gotBanners={!_.isEmpty(banners)}>
          <SS.MobileLink
            route="category"
            params={{ id: parent.urlName }}
            level={parent.level + 1}
          >
            {capfl(t('all from category'))} {parent.name}
          </SS.MobileLink>
          {_.map(chunks, chunk => {
            const chunkKey = sprintf(
              'navigation-item-dropdown-chunk-%1$s',
              _.join(_.map(chunk, item => item.id), '-')
            );
            return (
              <S.Chunk key={chunkKey}>
                {_.map(chunk, levelItem => {
                  const chunkItemKey = sprintf(
                    'navigation-item-dropdown-chunk-item-%1$s',
                    levelItem.id
                  );
                  return (
                    <DropdownContentItem
                      key={chunkItemKey}
                      data={levelItem}
                      chunksCount={chunksCount}
                    />
                  );
                })}
              </S.Chunk>
            );
          })}
        </S.Wrapper>
        {!_.isEmpty(banners) ? (
          <S.Banners>
            {_.map(banners, banner => {
              return (
                <S.Banner
                  key={'itemCategories-banner-' + banner.id}
                  bannerSize={banners.length}
                >
                  {banner.url ? (
                    <Link href={banner.url}>
                      <Image
                        {...banner.photo}
                        size="545x200.jpg"
                        lazyload={false}
                      />
                    </Link>
                  ) : (
                    <Image
                      {...banner.photo}
                      size="545x200.jpg"
                      lazyload={false}
                    />
                  )}
                </S.Banner>
              );
            })}
          </S.Banners>
        ) : (
          ''
        )}
      </div>
    );
  }
}

S.ChunkItem = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    width: ${({ chunkItemSize }) => (100 / chunkItemSize).toFixed(2) + '%'};
    padding-right: 30px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 30px;
      top: 0;
      height: 100%;
      width: 1px;
      opacity: 0.15;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

S.ChunkItemTitleLink = styled(RouteLink)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => _.get(theme, 'pagenav.item.linkTitle.color')};
    font-size: ${({ theme }) =>
      _.get(theme, 'pagenav.item.linkTitle.fontSize')};
    padding-bottom: 5px;
    margin-bottom: 15px;
    position: relative;
    font-weight: 600;
    align-items: center;
    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #eee;
    }
  }
`;

S.CategoryImage = styled(Image)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: block;
    margin-right: 10px;
    vertical-align: middle;
  }
`;

S.ChunkDropdown = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex !important;
  }
`;

S.ChunkGroup = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    width: ${({ groupSize }) => (100 / groupSize).toFixed(2) + '%'};
  }
`;

S.ChunkGroupListItem = styled.li`
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 10px;
  }
`;

S.ChunkGroupListItemLink = styled(RouteLink)`
  display: block;
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  text-decoration: none;
  padding: 15px 20px 15px 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: ${({ theme }) => _.get(theme, 'pagenav.item.linkTitle.mobile.fontSize', '.8em')};
  font-weight: ${({ theme }) => _.get(theme, 'pagenav.item.linkTitle.mobile.fontWeight', 'normal')};
  &:hover {
    color: ${({ theme }) => _.get(theme, 'colors.text')};
  }
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    font-size: ${({ theme }) => _.get(theme, 'pagenav.item.linkTitle.fontSize', '.9em')};
    font-weight: ${({ theme }) => _.get(theme, 'pagenav.item.linkTitle.fontWeight', 'normal')};
    color: ${({ theme }) => _.get(theme, 'colors.hell', '#666')};
    padding: 0;
    border: none;
    &:hover {
      color: ${({ theme }) => _.get(theme, 'link.hoverColor.primary', '#111')};
    }
  }
`;

S.ChunkGroupListItemLinkShowMore = styled(S.ChunkGroupListItemLink)`
  display: none;
  color: #fff;
  text-decoration: underline;
  padding: 15px 20px 15px 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: block;
    margin-top: 15px;
    color: ${({ theme }) => _.get(theme, 'colors.hell', '#666')};
    padding: 0;
    border: none;
    &:hover {
      color: ${({ theme }) => _.get(theme, 'link.hoverColor.primary', '#000')};
      text-decoration: underline;
    }
  }
`;

class DropdownContentItemInner extends Component {
  state = {
    isHidden: true
  };
  onClick = e => {
    e.preventDefault();
    const { isMD } = this.props;
    const { isHidden } = this.state;
    if (!isMD) {
      this.setState({ isHidden: !isHidden });
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    const { isHidden } = this.state;
    return !_.isEqual(isHidden, nextState.isHidden);
  }
  render() {
    const { isHidden } = this.state;
    const { data, chunksCount, t } = this.props;
    const {
      id,
      name,
      urlName,
      children,
      alternativePhoto,
      externalUrl,
      openInNewWindow = false,
      level = 1
    } = data;
    const slicedChildren = _.slice(children, 0, chunksCount > 3 ? 6 : 12);
    const itemsInGroupCount =
      chunksCount > 3
        ? slicedChildren.length
        : _.ceil(slicedChildren.length / 2);
    const groups = _.chunk(slicedChildren, itemsInGroupCount);
    return (
      <S.ChunkItem chunkItemSize={chunksCount}>
        <S.ChunkItemTitleLink
          href={externalUrl}
          route="category"
          params={{ id: urlName }}
          target={openInNewWindow ? '_blank' : ''}
        >
          {alternativePhoto && (
            <S.CategoryImage
              {...alternativePhoto}
              size="20x28.png"
              lazyload={false}
            />
          )}
          {name}
        </S.ChunkItemTitleLink>
        {_.isEmpty(groups) ? (
          <SS.MobileLink
            href={externalUrl}
            route="category"
            params={{ id: urlName}}
            level={level}
            target={openInNewWindow ? '_blank' : ''}
          >
            {name}
          </SS.MobileLink>
        ) : (
          <Fragment>
            <SS.MobileLink
              isActive={!isHidden}
              onClick={this.onClick}
              level={level}
            >
              {name}
              <SS.Icon
                className="nc-ic_arrow_drop_down_18px"
                isActive={!isHidden}
              />
            </SS.MobileLink>
            <S.ChunkDropdown isActive={!isHidden}>
              <SS.MobileLink
                href={externalUrl}
                route="category"
                params={{ id: urlName }}
                level={level + 1}
                target={openInNewWindow ? '_blank' : ''}
              >
                {capfl(t('all from category'))} {name}
              </SS.MobileLink>
              {_.map(groups, (group, i) => {
                return (
                  <S.ChunkGroup
                    key={'navigation-item-dropdown-chunk-item-' + id + '-group' + i}
                    groupSize={groups.length}
                  >
                    <List reset>
                      {_.map(group, level2Item => {
                        return (
                          <S.ChunkGroupListItem
                            key={
                              'navigation-item-dropdown-chunk-group-item-' +
                              level2Item.id
                            }
                          >
                            <S.ChunkGroupListItemLink
                              href={level2Item.externalUrl}
                              route="category"
                              params={{ id: level2Item.urlName }}
                              target={level2Item.openInNewWindow ? '_blank' : ''}
                            >
                              {level2Item.name}
                            </S.ChunkGroupListItemLink>
                          </S.ChunkGroupListItem>
                        );
                      })}
                      {i === groups.length - 1 &&
                      children.length > slicedChildren.length ? (
                        <S.ChunkGroupListItem
                          key={
                            'navigation-item-dropdown-chunk-item-' +
                            id +
                            '-show-more'
                          }
                        >
                          <S.ChunkGroupListItemLinkShowMore
                            route="category"
                            params={{ id: urlName }}
                          >
                            {capfl(t('show more'))} >
                          </S.ChunkGroupListItemLinkShowMore>
                        </S.ChunkGroupListItem>
                      ) : (
                        ''
                      )}
                    </List>
                  </S.ChunkGroup>
                );
              })}
            </S.ChunkDropdown>
          </Fragment>
        )}
      </S.ChunkItem>
    );
  }
}

const DropdownContentItem = withTranslation()(
  withTheme(withSizes(getMapSizesToPropsCustom())(DropdownContentItemInner))
);

export default withTranslation()(DropdownContent);
