import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { ICON_COMPARE } from 'common/constants/IconConstants';
import { getProductIdsToCompareSelector } from 'common/selectors/compare';
import { capfl } from 'common/utils';
import { fetchProductIdsToCompareFromCookie } from 'common/actions/CompareActions';

import SS from 'common/components/_partials/navigation/_default/_styles';

class CompareButton extends PureComponent {
  componentDidMount() {
    const { dispatchFetchProductIds } = this.props;
    dispatchFetchProductIds();
  }
  render() {
    const { t, productIdsToCompare } = this.props;
    return (
      <SS.UserActionsLink route="compare">
        <SS.UserActionsLinkIcon iconClassName={ICON_COMPARE}>
          <SS.UserActionsLinkIconText>
            {capfl(t('compare'))}
          </SS.UserActionsLinkIconText>
        </SS.UserActionsLinkIcon>
        {!_.isEmpty(productIdsToCompare) ? (
          <SS.UserActionsLinkCount>
            {productIdsToCompare.length}
          </SS.UserActionsLinkCount>
        ) : (
          ''
        )}
      </SS.UserActionsLink>
    );
  }
}

const makeMapStateToProps = () => {
  const getProductIdsToCompare = getProductIdsToCompareSelector();
  return (state, props) => {
    return { productIdsToCompare: getProductIdsToCompare(state, props) };
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchFetchProductIds: () => {
      dispatch(fetchProductIdsToCompareFromCookie());
    }
  };
};

const CompareButtonConnected = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CompareButton);

export default withTranslation()(CompareButtonConnected);
