import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { withTheme } from 'styled-components';
import _ from 'lodash';

import { setToObject } from 'common/utils/index';

import { getWishlistItemsCountSelector } from 'common/selectors/user';
import { reactiveTemplateConfigSelector } from 'common/selectors/theme';

const WishlistButtonVariant = loadable(props =>
  import(`./${props.blockVariant}`)
);

const WishlistButton = ({
  t,
  itemsCount = 0,
  blockSettings,
  theme,
  size = '',
  ...rest
}) => {
  return (
    <WishlistButtonVariant
      itemsCount={_.toNumber(itemsCount)}
      blockVariant={_.get(blockSettings, 'blockVariant')}
      customStyle={_.get(theme, 'wishlistButton.customStyle', '')}
      theme={theme}
      size={size}
      {...rest}
    />
  );
};

const blockSettings = {};
const makeMapStateToProps = () => {
  const getWishlistItemsCount = getWishlistItemsCountSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'WishlistButtonVariant'
  );
  return (state, props) => {
    return {
      itemsCount: getWishlistItemsCount(state, props),
      blockSettings: setToObject(blockSettings, {
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const WishlistButtonConnected = connect(
  makeMapStateToProps,
  undefined
)(WishlistButton);

export default withTheme(React.memo(WishlistButtonConnected));
