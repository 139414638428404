import _ from 'lodash';
import styled, { css } from 'styled-components';

import RouteLink from "common/components/_base/RouteLink";
import Icon from "common/components/styleguide/Icon";
import {withCssUnit} from "common/components/styleguide/utils";
import TextIcon from "common/components/styleguide/TextIcon";

const S = {};

S.MobileLink = styled(RouteLink)`
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  text-decoration: none;
  display: flex;
  padding: 15px;
  padding-left: ${({ level }) => (level ? ((level + 1) * 15) + 'px' : '15px')};
  font-size: .8em;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme }) => _.get(theme, 'pagenav.borderBottom')};
  &:hover {
    color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => _.get(theme, 'colors.white')};
      color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
    `};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: none;
  }
`;

S.Icon = styled(Icon)`
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(90deg);
    `};
`;

S.UserActionsLink = styled(S.MobileLink)`
  @media (min-width: ${({theme}) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    color: ${({theme}) => _.get(theme, 'colors.text')};
    border-bottom: none;
    justify-content: center;
    padding: 0 10px;
    border-bottom: none;
    border-right: ${({theme}) => _.get(theme, 'pagenav.borderBottom', null)};
    &:first-child {
      border-left: ${({theme}) => _.get(theme, 'pagenav.borderBottom', null)};
    }
    &:hover {
      color: ${({theme}) => _.get(theme, 'link.color.primary')};
      background: ${({ theme }) => _.get(theme, 'pagenav.item.hover.background', null)};
    }
  }
`;

S.UserActionsLinkIcon = styled(TextIcon)`
  .icon {
    font-size: 18px;
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      margin-right: 0;
    }
  }
`;

S.UserActionsLinkIconText = styled.span`
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: none;
  }
`;

S.UserActionsLinkCount = styled.span`
  font-size: 11px;
  font-weight: normal;
  background: ${({theme}) => _.get(theme, 'colors.primary')};
  color: ${({theme}) => _.get(theme, 'colors.textInverted')};
  display: inline-block;
  margin-left: 2px;
  padding: 3px 5px;
  border-radius: 100%;
`;

export default S;
