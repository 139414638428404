import React, { PureComponent } from 'react';
import { withSizes } from 'react-sizes';
import styled, { css, withTheme } from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from 'common/components/styleguide/utils';
import List from 'common/components/styleguide/List';
import Link from 'common/components/styleguide/Link';
import Row from 'common/components/styleguide/grid/Row';
import Container from 'common/components/styleguide/grid/Container';
import Column from 'common/components/styleguide/grid/Column';
import RouteLink from 'common/components/_base/RouteLink';
import Icon from 'common/components/styleguide/Icon';

import WishlistButton from 'common/components/_partials/navigation/_default/WishlistButton';
import CompareButton from 'common/components/_partials/navigation/_default/CompareButton';

import Image from 'common/components/_base/Image';

import DropdownContent from 'common/blocks/navigation/dropdown/NavigationDropdownVariant1';
import { ICON_CANCEL } from 'common/constants/IconConstants';

import SS from './_styles';

const S = {};

S.Navigation = styled.div`
  display: ${({ navIsHidden }) => navIsHidden ? 'none' : 'block'};
  background: ${({ theme }) =>
    _.get(
      theme,
      'pagenav.mobile.background',
      _.get(theme, 'page.background', 'white')
    )};
  box-shadow: ${({ theme }) => _.get(theme, 'pagenav.mobile.shadow')};
  position: ${({ theme }) =>
    _.get(theme, 'pagenav.mobile.position', 'absolute')};
  left: 0;
  top: ${({ theme }) => _.get(theme, 'pagenav.mobile.top', '0')};
  width: ${({ theme }) => _.get(theme, 'pagenav.mobile.width', '90%')};
  height: ${({ theme }) => _.get(theme, 'pagenav.mobile.height', '100%')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: block;
    background: ${({ theme }) => _.get(theme, 'pagenav.background')};
    position: relative;
    width: ${({ theme }) => _.get(theme, 'pagenav.width', '100%')};
    height: ${({ theme }) => _.get(theme, 'pagenav.height', 'auto')};
    border-top: none;
    box-shadow: ${({ theme }) => _.get(theme, 'pagenav.shadow')};
    top: ${({ theme }) => _.get(theme, 'pagenav.top', '0')};
  }
  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

S.List = styled(List)`
  width: ${({ theme }) => _.get(theme, 'pagenav.mobile.width', '100%')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    flex-direction: row;
    justify-content: ${({ theme }) =>
      _.get(theme, 'pagenav.justifyContent', 'flex-start')};
    width: ${({ theme }) => _.get(theme, 'pagenav.width', '100%')};
  }
`;

S.MobileTopBar = styled.div`
  display: flex;
  background: ${({ theme }) => _.get(theme, 'page.background')};
  width: 100%;
  height: 75px;
  border-bottom: ${({ theme }) => _.get(theme, 'pagenav.borderBottom')};
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: none;
  }
`;

S.Close = styled(Link)`
  display: flex;
  height: 44px;
  width: 44px;
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'colors.primary')};
  }
`;

S.LogoLink = styled(RouteLink)`
  display: block;
  text-decoration: none;
`;

S.CloseIcon = styled(Icon)`
  &.icon {
    font-size: 14px;
  }
`;

S.Inner = styled.nav`
  ${({ customStyle }) => css`
    ${_.get(customStyle, 'mobile', '')}
  `}
  background-color: ${({ theme }) =>
    _.get(
      theme,
      'pagenav.mobile.background',
      _.get(theme, 'page.background', 'white')
    )};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    background-color: ${({ theme }) =>
      _.get(
        theme,
        'pagenav.background',
        _.get(theme, 'page.background', 'white')
      )};
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: visible;
    ${({ customStyle }) => css`
      ${_.get(customStyle, 'desktop', '')}
    `}
  }
`;

const UserActions = styled.div`
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
  }
`;

const NavigationMenuVariant1 = ({
  theme,
  menu,
  logo,
  activeItems,
  navIsHidden,
  settings = {
    isCompareEnabled: true,
    isWishListEnabled: true,
    isMobileTopbarEnabled: true
  },
  functions
}) => {
  return (
    <S.Navigation customStyle={_.get(theme, 'pagenav.customStyle', '')} navIsHidden={navIsHidden}>
      {settings.isMobileTopbarEnabled ? (
        <S.MobileTopBar>
          <S.LogoLink route="home">
            <Image src={logo} alt="logo" lazyload={false} />
          </S.LogoLink>
          <S.Close
            href="#"
            onClick={_.get(functions, 'toggleNav') ? functions.toggleNav : null}
          >
            <S.CloseIcon className={ICON_CANCEL} />
          </S.Close>
        </S.MobileTopBar>
      ) : (
        ''
      )}
      <S.Inner customStyle={_.get(theme, 'pagenav.inner.customStyle', '')}>
        <S.List reset>
          {_.map(menu, item => {
            const { children } = item;
            if (_.isArray(children)) {
              if (children.length > 1) {
                return (
                  <ItemDropdown
                    key={'nav-dropdown-item-' + item.id}
                    data={item}
                    activeItems={activeItems}
                  />
                );
              } else if (children.length === 1) {
                <Item
                  route="category"
                  params={{ id: _.get(item, 'urlName') }}
                  key={'nav-item-' + item.id}
                  data={item}
                />
              }
            }
            return (
              <Item
                href={item.externalUrl}
                key={'nav-item-' + item.id}
                data={item}
                target={item.openInNewWindow ? '_blank' : ''}
              />
            );
          })}
        </S.List>
        <UserActions>
          {_.get(settings, 'isCompareEnabled') && <CompareButton />}
          {_.get(settings, 'isWishListEnabled') && <WishlistButton />}
        </UserActions>
      </S.Inner>
    </S.Navigation>
  );
};

//itemdropdown
S.ListItem = styled.li`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    align-items: center;
    flex-direction: row;
  }
`;

S.Link = styled(RouteLink)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    text-decoration: none;
    align-items: center;
    position: relative;
    height: ${({ theme }) => _.get(theme, 'pagenav.item.height', null)};
    color: ${({ theme }) => _.get(theme, 'pagenav.item.color', null)};
    font-weight: ${({ theme }) =>
      _.get(theme, 'pagenav.item.fontWeight', 'normal')};
    font-size: ${({ theme }) => _.get(theme, 'pagenav.item.fontSize', null)};
    padding: 0 5px 0 10px;
    &:hover {
      color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
      background: ${({ theme }) =>
        _.get(theme, 'pagenav.item.hover.background', null)};
    }
    ${({ isActive }) =>
      isActive &&
      css`
        color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
        background: ${({ theme }) =>
          _.get(theme, 'pagenav.item.hover.background', null)};
      `};
  }
`;

S.Dropdown = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    position: absolute;
    top: 100%;
    left: 10px;
    right: 10px;
    z-index: ${({ theme }) => _.get(theme, 'zIndex.megamenu')};
    box-shadow: ${({ theme }) => _.get(theme, 'shadow.basic')};
  }
`;

class ItemDropdownInner extends PureComponent {
  state = {
    isHidden: true
  };
  onMouseEnter = () => {
    const { isMD } = this.props;
    if (isMD) {
      this.setState({
        isHidden: false
      });
    }
  };
  onMouseLeave = () => {
    const { isMD } = this.props;
    if (isMD) {
      this.setState({ isHidden: true });
    }
  };
  onClick = e => {
    e.preventDefault();
    const { isMD } = this.props;
    const { isHidden } = this.state;
    if (!isMD) {
      this.setState({ isHidden: !isHidden });
    }
  };
  render() {
    const { isHidden } = this.state;
    const { data, activeItems } = this.props;
    const {
      urlName,
      name,
      children,
      banners,
      externalUrl,
      openInNewWindow = false,
      level = 9
    } = data;
    const chunksCount = children.length > 3 ? 4 : 3;
    const chunks = _.chunk(children, chunksCount);
    const activeItemUrl = _.get(activeItems, 'urlName', null);
    return (
      <S.ListItem
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <S.Link
          href={externalUrl}
          route="category"
          params={{ id: urlName }}
          isActive={!isHidden || activeItemUrl === urlName}
          target={openInNewWindow ? '_blank' : ''}
        >
          {name}
          <SS.Icon
            className="nc-ic_arrow_drop_down_18px"
            isActive={!isHidden}
          />
        </S.Link>
        <SS.MobileLink
          isActive={!isHidden || activeItemUrl === urlName}
          onClick={this.onClick}
          level={level}
        >
          {name}
          <SS.Icon
            className="nc-ic_arrow_drop_down_18px"
            isActive={!isHidden}
          />
        </SS.MobileLink>
        <S.Dropdown isActive={!isHidden}>
          <DropdownContent
            parent={{ name, urlName, level }}
            chunks={chunks}
            chunksCount={chunksCount}
            activeItemUrl={activeItemUrl}
            banners={banners}
          />
        </S.Dropdown>
      </S.ListItem>
    );
  }
}

const mapSizesToPropsCustom = ({ width }, { theme }) => {
  const { breakpoints } = theme;
  return {
    isMD: width >= breakpoints.md
  };
};

const ItemDropdown = withTheme(
  withSizes(mapSizesToPropsCustom)(ItemDropdownInner)
);

//item
S.LinkSingle = styled(RouteLink)`
  color: ${({ theme }) => _.get(theme, 'colors.text')};
  text-decoration: none;
  display: flex;
  padding: 15px;
  padding-left: ${({ level }) => (level ? (level + 1) * 15 + 'px' : '15px')};
  align-items: center;
  font-weight: ${({ theme }) => _.get(theme, 'pagenav.item.mobile.fontWeight', 'bold')};
  font-size: ${({ theme }) => _.get(theme, 'pagenav.item.mobile.fontSize')};
  justify-content: space-between;
  border-bottom: ${({ theme }) => _.get(theme, 'pagenav.borderBottom')};
  &:hover {
    color: ${({ theme }) => _.get(theme, 'colors.primary')};
  }
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    height: ${({ theme }) => _.get(theme, 'pagenav.item.height', null)};
    color: ${({ theme }) => _.get(theme, 'pagenav.item.color', null)};
    font-weight: ${({ theme }) =>
      _.get(theme, 'pagenav.item.fontWeight', 'normal')};
    font-size: ${({ theme }) => _.get(theme, 'pagenav.item.fontSize', null)};
    padding: 0 5px 0 10px;
    &:hover {
      color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
      background: ${({ theme }) =>
        _.get(theme, 'pagenav.item.hover.background', null)};
    }
    ${({ isActive }) =>
      isActive &&
      css`
        color: ${({ theme }) => _.get(theme, 'pagenav.item.hover.color', null)};
        background: ${({ theme }) =>
          _.get(theme, 'pagenav.item.hover.background', null)};
      `};
  }
`;

const Item = ({ data, href = null, target = null }) => {
  const { urlName, name } = data;
  if (name) {
    return (
      <li>
        {href ? (
          <S.LinkSingle href={href} target={target}>
            {name}
          </S.LinkSingle>
        ) : (
          <S.LinkSingle
            route="category"
            params={{ id: urlName }}
            target={target}
          >
            {name}
          </S.LinkSingle>
        )}
      </li>
    );
  }
  return null;
};

S.Wrapper = styled.div`
  box-shadow: ${({ theme }) => _.get(theme, 'pagenav.mobile.shadow', 'none')};
    ${({ theme, borderConfig }) =>
      _.get(borderConfig, 'top', false)
        ? `border-top: ${_.get(theme, 'pagenav.borderTop', null)};`
        : ''}
    ${({ theme, borderConfig }) =>
      _.get(borderConfig, 'bottom', false)
        ? `border-bottom: ${_.get(theme, 'pagenav.borderBottom', null)};`
        : ''}
    background: ${({ theme }) => _.get(theme, 'pagenav.background', null)};
  }
  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

S.Row = styled(Row)`
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    position: relative;
    z-index: 2;
  }
`;

S.NavColumn = styled(({ children, navIsHidden, ...rest }) => {
  return <Column {...rest}>{children}</Column>;
})`
  display: ${({ navIsHidden }) => (navIsHidden ? 'none' : 'block')};
  background: ${({ theme }) =>
    _.get(theme, 'pagenav.overlay.background', 'rgba(0, 0, 0, 0.45)')};
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: ${({ theme }) => _.get(theme, 'zIndex.megamenu')};
  padding: 0 !important;
  overflow: auto;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    padding: 0 10px !important;
    background: transparent;
    position: static;
    height: auto;
    width: 100%;
    display: flex !important;
    top: auto;
    right: auto;
    justify-content: space-between;
    z-index: 1;
    overflow: visible !important;
  }
`;

class NavigationWrapperMenuVariant1 extends PureComponent {
  render() {
    const {
      theme,
      menu,
      logo,
      activeItems,
      navIsHidden,
      borderConfig = { top: true, bottom: true },
      settings = {
        isCompareEnabled: true,
        isWishListEnabled: true,
        isMobileTopbarEnabled: true
      },
      functions
    } = this.props;

    return (
      <S.Wrapper
        borderConfig={borderConfig}
        customStyle={_.get(theme, 'pagenav.wrapper')}
      >
        <Container>
          <S.Row>
            <S.NavColumn size={{ xs: 12 }} navIsHidden={navIsHidden}>
              <NavigationMenuVariant1
                theme={theme}
                menu={menu}
                logo={logo}
                activeItems={activeItems}
                navIsHidden={navIsHidden}
                borderConfig={borderConfig}
                settings={settings}
                functions={functions}
              />
            </S.NavColumn>
          </S.Row>
        </Container>
      </S.Wrapper>
    );
  }
}

export default NavigationWrapperMenuVariant1;
